<template>
  <v-row class="teal lighten-2 pa-2 rounded mb-0 white--text">
    <v-col cols="2">
      Всего товаров: {{ wares.length }}
    </v-col>
    <v-col
      cols="8"
    >
      Декларант: {{ declarant }}<br>
      Контрагент: {{ contragent }}
    </v-col>
    <v-col cols="2">
      Сумма: {{ totalCost }}
    </v-col>
  </v-row>
</template>
<script>
export default {
  props:{
    wares:{
      required:true,
      type:Array
    },
    declaration:{
      required:true,
      type:Object
    },
  },
  computed:{
    typeDeclaration(){
      return this.declaration?.type_declaration?.declaration_kind_code
    },
    declarant(){
      const {declarant} = this.declaration
      return declarant?.declarant_name ? `${declarant.declarant_name} (${declarant?.declarant_json?.country_letter})`: ""
    },
    contragent(){
      const {sender, recipient} = this.declaration
      if(this.typeDeclaration === "ИМ"){
        return sender.sender_name ? `${sender.sender_name} (${sender?.sender_json?.country_letter})` : this.senderPerson
      }else if(this.typeDeclaration === "ЭК"){
        return recipient.recipient_name? `${recipient.recipient_name} (${recipient?.recipient_json?.country_letter})`: this.recipientPerson
      }else {
        return ""
      }
    },
    recipientPerson(){
      const {recipient_last_name, recipient_first_name, recipient_middle_name} = this.declaration?.recipient ?? {}
      return [recipient_last_name, recipient_first_name, recipient_middle_name].filter(i => i).join(' ')
    },
    senderPerson(){
      const {sender_last_name, sender_first_name, sender_middle_name} = this.declaration?.sender ?? {}
      return [sender_last_name, sender_first_name, sender_middle_name].filter(i => i).join(' ')
    },
    totalCost(){
      const {invoice_cost, invoice_currency_letter} = this.declaration?.about_wares ?? {}
      return `${invoice_cost ? parseFloat(invoice_cost).toLocaleString().replace(',', '.') : 0} ${invoice_currency_letter ? invoice_currency_letter : ''}`
    }
  }
}
</script>
