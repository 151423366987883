<template>
  <journal-goods-list
    :id="id"
    :show.sync="isShow"
    :headers="headers"
    :title="title"
    :wares="items"
  >
    <template #header>
      <goods-list-header
        :declaration="declaration"
        :wares="items"
      />
    </template>
  </journal-goods-list>
</template>
<script>
import JournalGoodsList from "@/components/journal/journal-goods-list.vue";
import GoodsListHeader from "@/components/dt/journal/goods-list-header.vue";
import {getCostString, getGrossWeightString, getJournalDeclarationTitle} from "@/helpers/text";


export default {
  components:{
    GoodsListHeader,
    JournalGoodsList
  },
  props:{
    show:{
      required:true,
      type:Boolean
    },
    id:{
      type:Number,
      required:true,
    },
    module:{
      type:String,
      required:true
    }
  },
  data(){
    return{
      declaration:{},
      items:[],
      headers:[
        {
          text:"№",
          value:"index"
        },
        {
          text:"Код товара",
          value:"tn_ved"
        },
        {
          text:"Наименование товара",
          value:"description_combined"
        },
        {
          text:"Происх.",
          value:"origin_country_letter"
        },
        {
          text:"Нетто",
          value:"net_weight"
        },
        {
          text:"Брутто",
          value:"gross_weight"
        },
        {
          text:"Стоимость",
          value:"cost"
        },
      ],
    }
  },
  computed:{
    isShow:{
      get(){
        return this.show
      },
      set(value){
        this.$emit('update:show', value)
      }
    },
    title(){
      return getJournalDeclarationTitle(this.declaration)
    },
  },
  watch:{
    show:{
      handler(value){
        if(value){
          this.fetchDocument()
        }
      },
      immediate:true
    }
  },
  methods:{
    setItems(wares){
      this.items = wares.map((item, idx) => {
        const {
          id,
          tn_ved,
          description,
          specific_description,
          net_weight,
          gross_weight,
          invoice_cost,
          invoice_currency_letter,
          origin_country_letter,
        } = item
        const cost = getCostString(invoice_cost, invoice_currency_letter)
        const description_combined = [description, specific_description].filter(v => v).join('; ')
        const grossWeight = getGrossWeightString(gross_weight)
        return {
          index:idx + 1,
          id,
          tn_ved,
          description_combined,
          origin_country_letter,
          net_weight,
          gross_weight:grossWeight,
          cost
        }
      })
    },
    errorHandler(err){
      if(err.response.status === 403){
        this.$error('Доступ запрещен')
      }else{
        this.$error()
      }
    },
    fetchDocument(){
      this.$store.dispatch(`dt/getDocumentInfo`, this.id).then(res => {
        this.declaration = res.data
        this.setItems(this.declaration.wares)
      }).catch(this.errorHandler)
    },
  }
}
</script>
